import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "../components/Button";
import Title from "../components/Title/Title";
import Para from "../components/Title/Para";
import { faqs } from "../assets/data/faq";
import axios from "axios";

import logo from "../assets/images/landingimages/logo.jpeg";
import bannerImg from "../assets/images/landingimages/cablebanner.png";
import smallImg from "../assets/images/landingimages/fsb.png";
import cableImg1 from "../assets/images/landingimages/cableimg1.png";
import cableImg2 from "../assets/images/landingimages/cableimg2.png";
import cabcir1 from "../assets/images/landingimages/cablecircle1.png";
import cabcir2 from "../assets/images/landingimages/cablecircle2.png";
import cabcir3 from "../assets/images/landingimages/cablecircle3.png";
import cabcir4 from "../assets/images/landingimages/cablecircle4.png";
import aboutMain from "../assets/images/landingimages/about-min.png";
import mile1 from "../assets/images/landingimages/mile1.png";
import mile2 from "../assets/images/landingimages/mile2.png";
import mile3 from "../assets/images/landingimages/mile3.png";
import mile4 from "../assets/images/landingimages/mile4.png";

import logo1 from "../assets/images/landingimages/logos1.png";
import logo2 from "../assets/images/landingimages/logos2.png";
import logo3 from "../assets/images/landingimages/logos3.png";
import logo4 from "../assets/images/landingimages/logos4.png";
import logo5 from "../assets/images/landingimages/logos5.png";
import logo6 from "../assets/images/landingimages/logos6.png";
import logo7 from "../assets/images/landingimages/logos7.png";

import cert1 from "../assets/images/landingimages/cert1.png";
import cert2 from "../assets/images/landingimages/cert2.png";
import cert3 from "../assets/images/landingimages/cert3.png";
import cert4 from "../assets/images/landingimages/cert4.png";
import cert5 from "../assets/images/landingimages/cert5.png";
import cert6 from "../assets/images/landingimages/cert6.png";
import cert7 from "../assets/images/landingimages/cert7.png";
import cert8 from "../assets/images/landingimages/cert8.png";

import custom1 from "../assets/images/landingimages/customers1.png";
import custom2 from "../assets/images/landingimages/customers2.png";
import custom3 from "../assets/images/landingimages/customers3.png";
import custom4 from "../assets/images/landingimages/customers4.png";
import custom5 from "../assets/images/landingimages/customers5.png";
import custom6 from "../assets/images/landingimages/customers6.png";
import custom7 from "../assets/images/landingimages/customers7.png";
import custom8 from "../assets/images/landingimages/customers8.png";

import manu1 from "../assets/images/landingimages/manu1.png";
import manu2 from "../assets/images/landingimages/manu2.png";

import pres1 from "../assets/images/landingimages/pres1.png";
import pres2 from "../assets/images/landingimages/pres2.png";

import frp1 from "../assets/images/landingimages/frp1.png";
import frp2 from "../assets/images/landingimages/frp2.png";

import expBig from "../assets/images/landingimages/exh2.png";
import expsmall1 from "../assets/images/landingimages/ex-big.png";
import expsmall2 from "../assets/images/landingimages/exh3.png";

import expsmall3 from "../assets/images/landingimages/exhsmall3.png";
import expsmall4 from "../assets/images/landingimages/exhsmall4.png";

import aboutimg from "../assets/images/landingimages/founder.jpeg";
import bothImg from "../assets/images/landingimages/bothImg.png";

import scannerImg from "../assets/images/landingimages/scanner.png";

import prod1 from "../assets/images/landingimages/prod1.png";
import prod2 from "../assets/images/landingimages/prod2.png";

import client1 from "../assets/images/landingimages/client1.png";
import client2 from "../assets/images/landingimages/client2.png";
import client3 from "../assets/images/landingimages/client3.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import pdf from "../assets/images/landingimages/M-Core OFC + FRP Master Catalogue (7.25 x 10.25 in).pdf";

import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";
import { GiRotaryPhone } from "react-icons/gi";

const Home = () => {
  const contactRef = useRef();
  const history = useHistory();

  const initialValues = {
    name: "",
    number: "",
    email: "",
    message: ""
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [valid, setValid] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target; // take name and value from input in destructring
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errorCheck = validate(formValues);
    setFormErrors(errorCheck);
    setIsSubmit(true);
    if (Object.keys(errorCheck).length === 0) {
      const res = await axios.post(
        "https://test23.goodgoodpiggy.in/api/leads/landing/",
        formValues
      );
      if (res.data.status === true) {
        alert("You Query Submit Succesfully.");
        resetInput();
        // history.push("/thank-you");
      } else {
        alert("Some Error Happen, Please Try Again");
      }
    }
  };

  const resetInput = () => {
    setFormValues(initialValues);
    setValid(false);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    //name
    if (!/^[a-z A-Z0-9]+$/.test(values.name) || /^[ ]+$/.test(values.name)) {
      errors.name = "Name is not valid";
    }

    if (!values.name) {
      errors.name = "Name is Required";
    }

    if (values.number) {
      if (values.number.length < 10) {
        errors.number = "Enter atleast 10 digits";
      }
    }

    if (!values.number) {
      errors.number = "Phone Number is Required";
    }

    //email
    if (!values.email) {
      errors.email = "Email is Required";
    }
    if (!regex.test(values.email) && values.email) {
      errors.email = "Email is not valid";
    }

    return errors;
  };

  const scrollhandler = (eleRef) => {
    console.log(eleRef.current);
    window.scrollTo({ top: eleRef.current.offsetTop, behavior: "smooth" });
  };


  return (
    <>
      {/* //topbar start  */}
      <section className="topbar">
        <div className="container">
          <div className="topbar-area">
            <div className="topbar-email">
              <Link to="/">
                <img src={logo} className="img-fluid logo-img" />
              </Link>
            </div>

            <div className="topbar-number">
              <div className="text">
                <p>request a calll back</p>
                <GiRotaryPhone className="topbar-icon" />
              </div>
              <div className="text">
                <p>+91 9155 60 6060</p>
                <FaPhoneAlt
                  className="topbar-icon"
                  style={{ fontSize: "1.25rem" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* // topbar end  */}

      {/* banner start */}
      <section className="banner_wrapper pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-md-start">
              <h1 className="banner-title">
                FRP Rods
                <br /> <span>&nbsp; &nbsp; &nbsp; &</span>
                &nbsp; &nbsp;&nbsp; Optical Fibre Cable
                <span>
                  &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
                  &nbsp;Manufacturers
                </span>
              </h1>
            </div>

            <div className="small-img-div">
              <img src={smallImg} className="img-fluid small-img" />
            </div>
          </div>
        </div>
      </section>
      {/* banner end */}

      {/* features start */}
      <section className="features_wrapper py-5">
        <div className="container mt-4 pt-4">
          <div className="row">
            <div className="col-12 banner-img">
              <img src={bannerImg} alt="" className="img-fluid cable-banner" />
            </div>
          </div>
          <div className="cable-imgs">
            <img src={cableImg1} alt="" className="img-fluid cableimg" />
            <img src={cableImg2} alt="" className="img-fluid cableimg" />
          </div>
          <div className="row">
            <div className="col-12">
              <h2 className="feature-heading">Connect with Excellence</h2>
              <h4 className="sub-heading">
                Where <span>Durability&nbsp;</span>meets{" "}
                <span>Performance</span>
              </h4>

              <div className="cable-imgs">
                <img src={cabcir1} alt="" className="img-fluid cableimg" />
                <img src={cabcir2} alt="" className="img-fluid cableimg" />
                <img src={cabcir3} alt="" className="img-fluid cableimg" />
                <img src={cabcir4} alt="" className="img-fluid cableimg" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* features end */}

      {/* faqs start */}
      <section className="about-cable">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="about-heading">
                <div className="about-logo">
                  <img src={logo} alt="" className="img-fluid about-logo-img" />
                </div>
                <div className="about-headtext">
                  <h2>
                    <b>INDIA’S</b> LEADING <span>OPTICAL FIBER CABLE</span>{" "}
                    MANUFACTURER
                  </h2>
                </div>
              </div>
              <div className="about-content">
                <p>
                  Founded in the early 1990s by Mr.{" "}
                  <span style={{ color: "#02AEDC" }}>Rajinder Makhija</span>,
                  our group began with{" "}
                  <span style={{ color: "#02AEDC" }}>antenna wires</span> and{" "}
                  <b>coaxial cables</b>. Over the years, we expanded our product
                  line to include <b>networking cables</b>. In 2018, we achieved
                  a major milestone by establishing a <b>state-of-the-art</b>{" "}
                  <span style={{ color: "#02AEDC" }}>
                    {" "}
                    Optical Fibre Cable Manufacturing plant
                  </span>{" "}
                  in Kundli, Sonipat Haryana. Today, we are a reputed
                  manufacturer of
                  <b>Optical Fibre Cables, FRP Rods</b>, and <b>HDPE</b>, known
                  for our quality, reliability, and cutting-edge technology.
                  With a combined annual capacity of over{" "}
                  <b>250,000 kilometers</b>, we are pioneers in the field of
                  telecommunication and broadband solutions.
                </p>
              </div>
              <div className="about-main-img">
                <img src={aboutMain} className="img-fluid about-img" />
              </div>
            </div>
          </div>
        </div>
        <div className="section-ending">
          <span></span>
          <span className="dot"></span>
          <span></span>
        </div>
      </section>
      {/* faqs end */}

      <section className="milestone">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="mile-heading">milestone achievements</h2>
            </div>
            <div className="col-md-6 col-12 mile-col">
              <div className="mileimg">
                <img src={mile4} className="img-fluid mile-img" />
              </div>
            </div>
            <div className="col-md-6 col-12 mile-col">
              <div className="mileimg">
                <img src={mile1} className="img-fluid mile-img" />
              </div>
            </div>
            <div className="col-md-6 col-12 mile-col">
              <div className="mileimg">
                <img src={mile3} className="img-fluid mile-img" />
              </div>
            </div>{" "}
            <div className="col-md-6 col-12 mile-col">
              <div className="mileimg">
                <img src={mile2} className="img-fluid mile-img" />
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="section-ending">
          <span></span>
          <span className="dot"></span>
          <span></span>
        </div>
      </section>

      <section className="certification">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="cerificate-heading">Certifications</h2>
            </div>
          </div>
          <div className="certifi-logos">
            <div className="logos-div">
              <img src={logo1} className="img-fluid logo-img" />
            </div>
            <div className="logos-div">
              <img src={logo2} className="img-fluid logo-img" />
            </div>
            <div className="logos-div">
              <img src={logo3} className="img-fluid logo-img" />
            </div>
            <div className="logos-div">
              <img src={logo4} className="img-fluid logo-img" />
            </div>
            <div className="logos-div">
              <img src={logo5} className="img-fluid logo-img" />
            </div>
            <div className="logos-div">
              <img src={logo6} className="img-fluid logo-img" />
            </div>
            <div className="logos-div">
              <img src={logo7} className="img-fluid logo-img" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <div className="cert-div">
                <img src={cert1} className="img-fluid cert-img" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="cert-div">
                <img src={cert2} className="img-fluid cert-img" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="cert-div">
                <img src={cert3} className="img-fluid cert-img" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="cert-div">
                <img src={cert4} className="img-fluid cert-img" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="cert-div">
                <img src={cert5} className="img-fluid cert-img" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="cert-div">
                <img src={cert6} className="img-fluid cert-img" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="cert-div">
                <img src={cert7} className="img-fluid cert-img" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="cert-div">
                <img src={cert8} className="img-fluid cert-img" />
              </div>
            </div>
          </div>
        </div>
        <div className="section-ending">
          <span></span>
          <span className="dot"></span>
          <span></span>
        </div>
      </section>

      <section className="customers">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="custom-heading">13000+ customers</h2>
              <p className="custom-sub-heading">All across india</p>
            </div>
          </div>
          <div className="row">
            <div className="custom-logos">
              <div className="custom-logo-div">
                <img
                  src={custom1}
                  className="img-fluid custom-logo"
                  style={{ width: "20rem", height: "9rem" }}
                />
              </div>
              <div className="custom-logo-div">
                <img
                  src={custom2}
                  className="img-fluid custom-logo"
                  style={{ width: "15rem", height: "9rem" }}
                />
              </div>

              <div className="custom-logo-div">
                <img
                  src={custom3}
                  className="img-fluid custom-logo"
                  style={{ width: "16rem", height: "15rem" }}
                />
              </div>
              <div className="custom-logo-div">
                <img
                  src={custom4}
                  className="img-fluid custom-logo"
                  style={{ width: "20rem", height: "9rem" }}
                />
              </div>
              <div className="custom-logo-div">
                <img
                  src={custom5}
                  className="img-fluid custom-logo"
                  style={{ width: "24rem", height: "12rem" }}
                />
              </div>
              <div className="custom-logo-div">
                <img src={custom6} className="img-fluid custom-logo" />
              </div>
              <div className="custom-logo-div">
                <img
                  src={custom7}
                  className="img-fluid custom-logo"
                  style={{ width: "15rem", height: "15rem" }}
                />
              </div>
              <div className="custom-logo-div">
                <img src={custom8} className="img-fluid custom-logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="section-ending">
          <span></span>
          <span className="dot"></span>
          <span></span>
        </div>
      </section>

      <section className="manufacture">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="manu-heading">MANUFACTURING FACILITY</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12 manu-col">
              <div className="manu-img-div">
                <img src={manu1} className="img-fluid manu-img" />
              </div>
            </div>
            <div className="col-md-6 col-12 manu-col">
              <div className="manu-img-div">
                <img
                  src={manu2}
                  className="img-fluid manu-img"
                  style={{ width: "28rem" }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 manu-sin-img">
              <img
                src={mile4}
                className="img-fluid mile-img"
                style={{ width: "26rem" }}
              />
            </div>
          </div>
        </div>
        <div className="section-ending">
          <span></span>
          <span className="dot"></span>
          <span></span>
        </div>
      </section>

      <section className="presence">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="pres-heading">our presence</h2>
            </div>
          </div>
          <div className="row pres-row">
            <div className="col-md-6">
              <div className="sec-img">
                <img src={pres1} className="img-fluid pres-img" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="sec-location">
                <ul>
                  <li>Ahmedabad</li>
                  <li>ayodhya</li>
                  <li>bangalore</li>
                  <li>chennai</li>
                  <li>coimbatore</li>

                  <li>delhi</li>
                  <li>anumangarh</li>
                  <li>hyderabad</li>
                  <li>imphal</li>
                  <li>indore</li>

                  <li>kanpur</li>
                  <li>kolkata</li>
                  <li>lucknow</li>
                  <li>madurai</li>
                  <li>mumbai</li>

                  <li>noida</li>
                  <li>patna</li>
                  <li>paryagraj</li>
                  <li>pune</li>
                  <li>ranchi</li>

                  <li>sri ganganagar</li>
                  <li>srinagar</li>
                  <li>surat</li>
                  <li>vadodara</li>
                  <li>varanasi</li>
                  <li>vijaywada</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section-ending">
          <span></span>
          <span className="dot"></span>
          <span></span>
        </div>
      </section>

      <section className="refrocement">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="heading">REINFORCEMENTS</h2>
            </div>
          </div>
          <div className="row mb-4 data-row-1">
            <div className="col-md-7 col-12">
              <div className="main-heading">
                <h3>FRP Rods</h3>
              </div>
              <div className="main-content">
                <p>
                  FRP rods are composite materials with a polymer matrix
                  reinforced by fibers like glass or carbon. They boast a high
                  strength-to-weight ratio, making them lightweight yet very
                  strong. These rods are corrosion-resistant, have great tensile
                  strength, and endure tough conditions, ideal for diverse
                  structural uses.
                </p>
              </div>
            </div>
            <div className="col-md-5 col-12">
              <div className="frp-imgs">
                <img src={frp1} className="img-fluid frp-img" />
                <img src={frp2} className="img-fluid frp-img" />
              </div>
            </div>
          </div>
          <div className="row data-row-2">
            <div className="col-md-7 col-12">
              <div className="row-heading2">
                <h3>Distinct Features of M-Core FRP:</h3>
              </div>
              <div className="data-list">
                <ul>
                  <li>
                    Strong modulus and tensile strength maintain less than 0.25%
                    fiber strain.
                  </li>
                  <li>Thickness variety from 0.29 to 6.00 mm.</li>
                  <li>Precise diameter and shape consistency.</li>
                  <li>Flexible, small bend radius design.</li>
                  <li>
                    Excellent aging resistance for durability in tough
                    conditions.
                  </li>
                  <li>Broad compatibility with various jacket materials.</li>
                  <li>
                    Longer reels for reduced waste and improved production
                    efficiency.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-5 col-12 last-data">
              <div className="prod-heading">
                <h3>Product Range</h3>
              </div>
              <div className="prod-list">
                <ul>
                  <li>FRP ROD for all ypes of cables</li>
                  <li>Flat FRP for flexible armoring</li>
                  <li>Bend insensititve FRP Rod</li>
                  <li>Coated FRP Rod</li>
                  <li>Water Blocking FRP</li>
                  <li>Up Jacketed FRP Rod</li>
                  <li>Steel embedded FRP Rod</li>
                  <li>ARP Rod</li>
                  <li>Low Bend FRP Rod</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row row-data-3">
            <div className="col-md-7 col-12">
              <div className="coated-data">
                <div className="coat-heading">
                  <h2>EAA Coated</h2>
                </div>
                <div className="cat-list">
                  <ul>
                    <li>High Tensile Strength</li>
                    <li>Low moisture absorption</li>
                    <li>High performance capabilities</li>
                    <li>Range 0.4 - 11 mm</li>
                    <li>High dielectric strength</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="coat-img">
                <img src={cableImg1} className="img-fluid coat-img1" />
              </div>
            </div>
          </div>

          <div className="row row-data-3">
            <div className="col-md-7 col-12">
              <div className="coated-data">
                <div className="coat-heading">
                  <h2>ARP Rod</h2>
                </div>
                <div className="cat-list">
                  <ul>
                    <li>High Tensile Strength</li>
                    <li>Light Weight</li>
                    <li>High performance capabilities</li>
                    <li>Range 0.4 - 11 mm</li>
                    <li>Low moisture absorption</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="coat-img">
                <img src={cableImg2} className="img-fluid coat-img1" />
              </div>
            </div>
          </div>
        </div>
        <div className="section-ending">
          <span></span>
          <span className="dot"></span>
          <span></span>
        </div>
      </section>

      <section className="exhibition">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading">
                <h2>
                  LATEST <span>EXHIBITIONS</span>
                </h2>
                <h4>presence</h4>
              </div>
            </div>
            <div className="col-12 mb-5 exh-col">
              <img src={expBig} className="img-fluid exh-big" />
            </div>

            <div className="col-md-6 col-12">
              <img src={expsmall1} className="img-fluid exh-smll" />
            </div>
            <div className="col-md-6 col-12">
              <img src={expsmall2} className="img-fluid exh-smll" />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6 col-12">
              <img src={expsmall3} className="img-fluid extra-smll" />
            </div>
            <div className="col-md-6 col-12">
              <img src={expsmall4} className="img-fluid extra-smll" />
            </div>
          </div>
        </div>
        <div className="section-ending">
          <span></span>
          <span className="dot"></span>
          <span></span>
        </div>
      </section>

      <section className="about-page-wrapper pt-5">
        <div className="about-section container">
          <div className="row">
            <div className="col-12">
              <div className="heading">
                <h2>
                  THE ONLY BOOK IN <span>CABLE INDUSTRY</span>
                </h2>
                <h4>FOR CABLE DEALERS</h4>
              </div>
            </div>
          </div>
          <div className="about-text row">
            <div className="about-quality col-lg-6 col-md-6 col-12">
              <img src={bothImg} className="img-fluid book-img" />
            </div>
            <div className="about-image text-center col-lg-6 col-md-6 col-12 bg-orange">
              <img
                src={aboutimg}
                alt="about-image img-fluid"
                height="100"
                width="100"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-12">
              <div className="scan-data">
                <h4>
                  Get <span>7 Strategies</span> to Increase <span>Sales</span>{" "}
                  and Maximize <span>Profits</span>
                </h4>
                <div className="button-div">
                  <button>
                    Download |{" "}
                    <MdOutlineFileDownload className="download-icon" />
                  </button>
                  <p>FREE COPY NOW</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="scanner-div">
                <img src={scannerImg} className="img-fluid scanner-img" />
              </div>
            </div>
          </div>
        </div>
        <div className="section-ending">
          <span></span>
          <span className="dot"></span>
          <span></span>
        </div>
      </section>

      <section className="products">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading">
                <h2>
                  OTHER <b style={{ color: "#1a4789" }}>PRODUCTS</b>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-12 prod-col">
              <div className="prod-det">
                <h4>Networking cable</h4>
                <ul>
                  <li>Cat-5 Cable</li>
                  <li>Cat-6 Cable</li>
                </ul>
                <img src={prod1} className="img-fluid prod-img" />
              </div>
            </div>
            <div className="col-md-6 col-12 prod-col">
              <div className="prod-det">
                <h4>CCTV Cables</h4>
                <ul>
                  <li>3+1 CCTV Cables</li>
                  <li>Co-axial Cables</li>
                </ul>
                <img src={prod2} className="img-fluid prod-img" />
              </div>
            </div>
          </div>
        </div>
        <div className="section-ending">
          <span></span>
          <span className="dot"></span>
          <span></span>
        </div>
      </section>

      <section className="testimonial">
        <div className="container">
          <div className="row">
            <div className="col-12 heading">
              <h2>client testimonials</h2>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-md-3 col-12">
              <div className="test-img">
                <img src={client2} className="img-fluid client-img" />
              </div>
            </div>
            <div className="col-md-9 col-12 test-content">
              <div className="test-data">
                <p>
                  Working with <span>M-Core</span> has{" "}
                  <span>increased my profits 4x</span> in <span>3 years</span>,
                  and I now provide customers with{" "}
                  <b>more fiber cable choices</b> and
                  <span>better value for</span> their <span>money</span>.
                </p>
                <p>
                  <span>-Sumit Advani</span> - Sanay Electronics (Mumbai)
                </p>
              </div>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-md-9 col-12 test-content">
              <div className="test-data">
                <p>
                  जब से <span>M-Core</span> की <span>Cables</span> बेचबे ना
                  शुरूशु करा है,है <span>Repeat Customers</span> ज्या दा बा र वा
                  पस आ रहे हैं,हैं जिससे मेरे <span>Profits</span> में बढ़ोतरी
                  हुई है,है और <b>M-Core Fiber Cables</b> की <span>सफलता</span>{" "}
                  ने मेरेमेरे अन्य <span>Products</span> की <span>Sale</span> को{" "}
                  <span>भी बढ़ाया</span> है।
                </p>
                <p>
                  <span>-Amol</span> - Optibre infra Tech (Nagpur)
                </p>
              </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="test-img">
                <img src={client1} className="img-fluid client-img" />
              </div>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-md-3 col-12">
              <div className="test-img">
                <img src={client3} className="img-fluid client-img" />
              </div>
            </div>
            <div className="col-md-9 col-12 test-content">
              <div className="test-data">
                <p>
                  <span>M-Core Cables</span> use करने के बा द से मेरा अपना{" "}
                  <span>network</span> का फी
                  <span>strong</span> हुआ है। MCore की <span>team</span> के साथ
                  जुड़जुने से मुझेमु झे Business में भी <span>Growth</span> मिली
                  है, जिससे मैं एक <span>ISP</span> के साथ साथ{" "}
                  <span>Reseller</span> भी बन गया हूँ।
                </p>
                <p>
                  <span>-Gaurav Kapoor</span> M/s Saanvi Enterprises
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="contact-form-section py-5" ref={contactRef}>
        <div className="contact-auth-section col-lg-6 col-md-6 ml-auto mr-auto">
          <div className="contact-authentication">
            {/* <img src={iconImg} className="img-fluid" /> */}
            <h2 className="text-center mb-4">Submit your Query!</h2>
            <form method="POST" onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group col-md-6 mb-2">
                  <label>
                    <b>Name</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Name"
                    name="name"
                    onChange={handleChange}
                    value={formValues.name}
                  />
                  <small className="text-red">{formErrors.name}</small>
                </div>
                <div className="form-group col-md-6 mb-2">
                  <label>
                    <b>Contact</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="10 Digit Mobile Number"
                    name="number"
                    value={formValues.number}
                    onChange={handleChange}
                  />
                  <small className="text-red">{formErrors.number}</small>
                </div>
                <div className="form-group col-12 mb-2">
                  <label>
                    <b>Your Email?</b>
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Your Email"
                    value={formValues.email}
                    onChange={handleChange}
                  />
                  <small className="text-red">{formErrors.email}</small>
                </div>
                <div className="form-group col-12 mb-2">
                  <label>
                    <b>Your Requirement?</b>
                  </label>
                  <textarea
                    // type="email"
                    name="message"
                    className="form-control"
                    placeholder="Your Requirement"
                    value={formValues.message}
                    onChange={handleChange}
                  />
                  <small className="text-red">{formErrors.message}</small>
                </div>

                <div className="form-group col-12 form-btn">
                  <Button big>Submit Now</Button>
                </div>
                {/* <a href={pdf} rel="noopener noreferrer" target="_blank" className="download-btn">
                  <button>
                    {" "}
                    Download |{" "}
                    <MdOutlineFileDownload className="download-icon" />
                  </button >
                </a> */}
              </div>
            </form>
            {/* <a href={pdf} rel="noopener noreferrer" target="_blank" className="download-btn">
                  <button>
                    {" "}
                    Download E-Catalog |{" "}
                    <MdOutlineFileDownload className="download-icon" />
                  </button >
                </a> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
