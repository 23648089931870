import React, { useEffect, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import {
  FaFacebookF,
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
  FaInstagram,
  FaLinkedin,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
  FaPinterestP,
  FaPinterestSquare
} from "react-icons/fa";
import { BsEnvelope } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import logo from "../assets/images/main-logo1.png";
import footerMain from "../assets/images/landingimages/footerImg.png";
import pdf from "../assets/images/landingimages/M-Core OFC + FRP Master Catalogue (7.25 x 10.25 in).pdf";
import { MdOutlineFileDownload } from "react-icons/md";

const Footer = () => {
  return (
    <>
      <section className="footer">
        <footer className="footer-top">
          <div className="container">
            <div className="row">
              <div className="about-main-img">
                <img src={footerMain} className="img-fluid about-img"/>
              </div>
              <div className="col-12">
                <div className="footer-main">
                  {/* <img src={footerMain} className="footer-img" /> */}
                  <h2 className="img-heading">TALK TO OUR EXPERTS</h2>
                  <p className="img-para">M-CORE INDUSTRIES PVT. LTD.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="footer-col col-lg-8 col-md-6 col-sm-6 col-12 sm-mb-3">
                <div className="contact-box mt-30">
                  <div className="contact-icon">
                    <FaPhoneAlt className="contact-icons" />
                  </div>
                  <div className="contact-details">
                    <a href="tel:919155606060" title="Call Now">
                      +91- 9155 60 6060
                    </a>
                  </div>
                </div>
                <div className="contact-box mt-30">
                  <div className="contact-icon">
                    <BsEnvelope className="contact-icons" />
                  </div>
                  <div className="contact-details">
                    <a href="tel:919155606060" title="Call Now">
                      info@mcorecables.com
                    </a>
                  </div>
                </div>
                <div className="contact-box mt-30">
                  <div className="contact-icon">
                    <MdLocationOn className="contact-icons" />
                  </div>
                  <div className="contact-details">
                    <p className="contact-loc">
                      Plot No 259 Sector-53, HSIIDC, Phase 5, Kundli, Sonipat,
                      Haryana 131028
                    </p>
                  </div>
                </div>
              </div>

              <div className="footer-col col-lg-4 col-md-6 col-sm-6 col-12">
                <a
                  href={pdf}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="download-btn"
                >
                  <button>
                    {" "}
                    Download E-Catalog |{" "}
                    <MdOutlineFileDownload className="download-icon" />
                  </button>
                </a>
                <div className="social-links mb-1">
                  <a href="https://www.facebook.com/mcorecable" target="_blank">
                    <FaFacebookF className="fb" />
                  </a>
                  <a href="https://www.instagram.com/mcorecables/" target="_blank">
                    <FaInstagram className="insta" />
                  </a>
                  <a href="https://www.linkedin.com/company/mcorecables/">
                    <FaLinkedinIn className="linked" />
                  </a>
                  <a href="https://twitter.com/mcorecables">
                    <FaTwitter className="tweet" />
                  </a>
                  <a href="https://www.youtube.com/@mcorecables">
                    <FaYoutube className="you" />
                  </a>
                  <a href="https://in.pinterest.com/mcorecables/">
                    <FaPinterestP className="pin" />
                  </a>
                </div>
                <div className="social-bottom">
                  <p>Follow us - <b>@mcorecables</b></p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="text-center">© 2024. All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
