import React from "react";
import "./assets/scss/style.scss";
import Home from "./pages/Home";
import Thanks from "./pages/Thanks";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollTop";


function App() {
 
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/thank-you">
          <Thanks />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
